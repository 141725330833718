export const environment = {
    production: false,
    runtime: 'development',
    consoleLogging: true,
    loginURL: '/siteminderagent/forms/Login.fcc',
    recaptchaSiteKey: '6LdPtCIUAAAAAP4O48BtSut6TShU8SHRg9egaeKj',
    producerManagementRESTURL: '/ProducerManagementRest/associates/associate',
    producerPortalPath: '/Producer',
    bondClickPath: '/OnDemandSite'    
};
