export class GlobalConstants {    
    public static readonly legalDisclaimer = `Intact Insurance Specialty Solutions is the marketing brand for the insurance company subsidiaries of Intact Insurance Group USA LLC. 
        Coverages may be underwritten by one of the following insurance companies: Atlantic Specialty Insurance Company, a New York insurer; 
        Homeland Insurance Company of New York, a New York insurer; Homeland Insurance Company of Delaware, a Delaware insurer; OBI America Insurance Company, 
        a Pennsylvania insurer; OBI National Insurance Company, a Pennsylvania insurer; or The Guarantee Company of North America USA, a Michigan insurer. 
        Each of these insurers maintains its principal place of business at 605 Highway 169 N, Plymouth, MN 55441, except The Guarantee Company of North America USA, 
        which is located at One Towne Square, Southfield, MI 48076. This material is intended as a general description of certain types of insurance coverages and services. 
        Coverages and availability vary by state; exclusions and deductibles may apply. Please refer to your insurance policy or consult with your independent insurance 
        advisor for information about coverages, terms and conditions. Some coverage may be written by a surplus lines insurer through a licensed surplus lines broker. 
        Surplus lines insurers do not generally participate in state guaranty funds and insureds are therefore not protected by such funds.`;

    public static readonly dewarLegalDisclaimer = `Tuition Refund Plan policies are underwritten by Atlantic Specialty Insurance Company and OBI National Insurance Company. 
    Plan policies are produced by A.W.G. Dewar, Inc., dba A.W.G. Dewar Insurance Agency, Four Batterymarch Park, Quincy, MA 02169. The name of each student is listed on a 
    policy which is held by the school's business office, not as agent for the insurance company, but on behalf of insured students and their parents. 
    A.W.G. Dewar, Inc. is the originator of the trademarked Tuition Refund Plan.`;

    public static smAuthReasons = {
        'Initial Login': 0,
        'User ID Disabled': 7,
        'Password Change Required': 20,
        'Excessive Logon Attempts': 24,
    };

    public static brands = {
        Intact: 'intactspecialty',
        DewarTRP: 'tuitionrefundplan',
        DewarCR: 'collegerefund',
        NMU: 'nmu',
    };
    public static readonly imageLocation = 'assets/images/';    
    public static readonly routeValidateCode = '/validate-code';
    public static readonly routeSendCode = '/send-code';
    public static readonly producerPortalURL = '/Producer';
};

export class RegExPatterns {
    public static readonly ValidationRegex = {
        email: `^[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\\.){1,125}[a-zA-Z]{2,63}$`,
        phone: `^\\(?([0-9]{3})\\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$`,
        zipCode: `^\\d{5}(-{0,1}\\d{4})?$`,
        canadianPostalCode: `^[A-Za-z]\\d[A-Za-z][ -]?\\d[A-Za-z]\\d$`,
    };
};

type PwdScoreDescription = {
    [key: number]: string;
}

export class PasswordScoreDescriptions {
    public static readonly passwordScoreDescription: PwdScoreDescription = {
        0: 'Too Short',
        1: 'Weak',
        2: 'Fair',
        3: 'Good',
        4: 'Strong'        
    };
}
