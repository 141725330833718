import { Injectable } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DynamicRecaptchaLoaderService {
    // public ready: Observable<any>;
    public language = '';

    constructor(private translate: TranslateService) {
        // this.init();
        // this.ready = RecaptchaDynamicLanguageLoaderService.ready.asObservable();
        // script.src = `https://www.google.com/recaptcha/api.js?render=explicit&onload=ng2recaptchaloaded${langParam}`;

        // this.updateLanguage();
        // this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        //     this.updateLanguage();
        // });
    }

    public updateLanguage(): void {
        let captchaDiv = document.getElementById('recaptcha-container');
        if (captchaDiv !== null) {
            captchaDiv!.innerHTML = '';
        }

        this.language = this.translate.currentLang;
        const script = document.createElement('script') as HTMLScriptElement;
        script.innerHTML = '';
        const langParam = this.language ? '&hl=' + this.language : '';
        script.src = `https://www.google.com/recaptcha/api.js?render=explicit&onload=ng2recaptchaloaded${langParam}`;
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);
    }

    private ng2recaptchaloaded() {
        // RecaptchaDynamicLanguageLoaderService.ready.next(grecaptcha);
    }
}
