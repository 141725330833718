import { Component } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { EnvironmentService } from '@app-core';
import { TranslateModule } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'app-logout',
    standalone: true,
    imports: [TranslateModule, RouterLink, RouterLinkActive],
    templateUrl: './logout.component.html',
    styleUrl: './logout.component.scss',
})
export class LogoutComponent {
    domain: string = '';
    brand: string = '';
    originatingSite: string = '';
    target!: string;

    constructor(
        private environmentService: EnvironmentService,
        private cookieService: CookieService,
    ) {
        this.brand = this.environmentService.brand;
        this.domain = this.environmentService.domain;
        this.originatingSite = this.environmentService.originatingSite;
    }

    returnToLogin(event: Event): void {
        event.preventDefault();
        window.location.href = this.environmentService.targetResourceUrl;
    }
}
