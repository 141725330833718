import { Injectable } from '@angular/core';
import { environment } from '@app-env';

@Injectable({
    providedIn: 'root',
})
export class LoggingService {
    constructor() {}

    public static log(message?: any, ...optionalParams: any[]): void {
        if (!environment.production) {
            if (optionalParams.length > 0) {
                console.log(message, optionalParams);
            } else {
                console.log(message);
            }
        }
    }
}
