import { Injectable } from '@angular/core';
import { FormGroup, ValidationErrors } from '@angular/forms';

@Injectable({
    providedIn: 'root',
})
export class CommonService {
    constructor() {}

    getQueryString(stringToFind: string) {
        const stringToFindUpper = stringToFind.toLocaleUpperCase();
        const myHref = window.location.href;
        const indexOfDelim = myHref.indexOf('?');
        if (indexOfDelim < 0) {
            return '';
        }
        const urlParts = myHref.substring(indexOfDelim + 1);
        let decoded = '';
        const params = urlParts.split('&');

        for (const param of params) {
            if (param.indexOf(stringToFindUpper) > -1 || param.indexOf(stringToFind) > -1) {
                const indexOfEquals = param.indexOf('=');
                decoded = param.substring(indexOfEquals + 1);
            }
        }
        return decoded;
    }

    getQueryStringObject() {
        const myHref = window.location.href;
        const indexOfDelim = myHref.indexOf('?');
        if (indexOfDelim < 0) {
            return {};
        }
        const urlParts = myHref.substring(indexOfDelim + 1);
        const params = urlParts.split('&');
        const queryObject: { [key: string]: string } = {}; // Add index signature
        for (const param of params) {
            const indexOfEquals = param.indexOf('=');
            const key = param.substring(0, indexOfEquals);
            const value = param.substring(indexOfEquals + 1);
            queryObject[key] = value;
        }
        return queryObject;
    }

    emptyOrNull(val: string) {
        let emptyOrNull = false;
        if (val === null || val === undefined || val.length === 0) {
            emptyOrNull = true;
        }
        return emptyOrNull;
    }

    cleanTargetParam(param: string): string {
        const decodedURI = decodeURI(param);
        let decoded = decodedURI.replace(/--/g, '-');

        if (decoded.startsWith('-SM-') || decoded.startsWith('-sm-')) {
            decoded = decoded.substring(4);
        }
        // if (decoded.indexOf('-SM-') > -1 || decoded.indexOf('-sm-') > -1) {
        //     decoded = decoded.substr(4);
        // }
        // check for #!/ at end of string
        // if (decoded.indexOf('#!/') > -1) {
        //     decoded = decoded.substr(0, decoded.length - 3);
        // }
        return decoded;
    }

    getFormValidationErrors(form: FormGroup) {
        console.log('%c ==>> Validation Errors: ', 'color: red; font-weight: bold; font-size:25px;');

        let totalErrors = 0;

        Object.keys(form.controls).forEach((key) => {
            const controlErrors: ValidationErrors | null = form.get(key)?.errors || null;
            if (controlErrors != null) {
                totalErrors++;
                Object.keys(controlErrors).forEach((keyError) => {
                    console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                });
            }
        });
        console.log('Number of errors: ', totalErrors);
    }

}
