import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoggingService } from '@app-core';
import { environment } from '@app-env';
import { Producer, ProducerChangePassword, ProducerPhoneType, ProducerResetPassword, ValidateToken, OptStart } from '@app-models';
import { Observable, tap } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ProducerService {
    private httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    constructor(private httpClient: HttpClient) {}

    validatePasswordToken(guid: ValidateToken): Observable<string> {
        const restURL = `${environment.producerManagementRESTURL}/validatePasswordToken/`;
        return this.httpClient
            .put<string>(restURL, guid, this.httpOptions)
            .pipe(tap({ next: (data) => LoggingService.log('validatePasswordToken: ' + JSON.stringify(data), restURL) }));
    }

    forgotPassword(producer: Producer): Observable<string> {
        const restURL = `${environment.producerManagementRESTURL}/forgotPassword/`;
        return this.httpClient
            .put<string>(restURL, producer, this.httpOptions)
            .pipe(tap({ next: (data) => LoggingService.log('forgotPassword: ' + JSON.stringify(data), restURL) }));
    }

    resetPassword(resetPassword: ProducerResetPassword): Observable<string> {
        const restURL = `${environment.producerManagementRESTURL}/resetPassword/`;
        return this.httpClient
            .put<string>(restURL, resetPassword, this.httpOptions)
            .pipe(tap({ next: (data) => LoggingService.log('resetPassword: ' + JSON.stringify(data), restURL) }));
    }

    changePassword(changePassword: ProducerChangePassword): Observable<string> {
        const restURL = `${environment.producerManagementRESTURL}/changePassword/`;
        return this.httpClient
            .put<string>(restURL, changePassword, this.httpOptions)
            .pipe(tap({ next: (data) => LoggingService.log('changePassword: ' + JSON.stringify(data), restURL) }));
    }

    // for MFA changes - get type of phone for user to see if text or call - will always return text for now
    getPhoneInformation(): Observable<ProducerPhoneType> {
        const restURL = `${environment.producerManagementRESTURL}/api/mfaphone`;
        return this.httpClient.get<ProducerPhoneType>(restURL);
    }

    // for MFA changes // sendType is sms or call. return plain text true if code is sent and false if it isnt
    sendCode(sendType: string): Observable<string> {
        const restURL = `${environment.producerManagementRESTURL}/api/sendcode/`;
        return this.httpClient.get<string>(restURL + sendType);
    }
    // for MFA changes
    validateCode(validationCode: string): Observable<string> {
        const restURL = `${environment.producerManagementRESTURL}/api/mfavalidate/`;
        return this.httpClient.get<string>(restURL + validationCode);
    }

    otpStart(optStart: OptStart): Observable<string> {
        const restURL = `${environment.producerManagementRESTURL}/ext/api/otpstart/`;
        return (
            this.httpClient
                .put<string>(restURL, optStart, this.httpOptions)
                .pipe(tap({ next: (data) => LoggingService.log('optStart: ' + JSON.stringify(data), restURL) }))
        );
    }
}
