import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {  Subject, Observable, filter } from 'rxjs';

interface IAlertOptions {
    id?: string;
    heading?: string;
    keepAfterRouteChange?: boolean;
    autoClose?: boolean;
    fade?: boolean;
}

export class Alert {
    id: string = AlertService.defaultAlertID;
    type: string = AlertType.success;
    message: string = '';
    heading: string = '';
    autoClose: boolean = false;
    keepAfterRouteChange: boolean = false;
    fade: boolean = false;

    constructor(init?: Partial<Alert>) {
        Object.assign(this, init);
    }
}

export const AlertType = {
    success: 'success',
    danger: 'danger',
    info: 'info',
    warning: 'warning',
    primary: 'primary',
    secondary: 'secondary',
    light: 'light',
    dark: 'dark',
};

/*
 ** The message service enables any component in the application to display alert messages at the top of the page via the app component.
 ** It has methods for displaying success and error messages, and a getMessage() method that returns an Observable that is used by the app
 ** component to subscribe to notifications for whenever a message should be displayed.
 */

@Injectable({
    providedIn: 'root',
})
export class AlertService {
    public static defaultAlertID = 'default-alert';
    private alertSubject = new Subject<any>();
    private keepAfterRouteChange = false;

    constructor(
        private router: Router,
        private translate: TranslateService,
    ) {
        // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterRouteChange) {
                    // only keep for a single route change
                    this.keepAfterRouteChange = false;
                } else {
                    // clear alert message
                    this.clearAlerts();
                }
            }
        });
    }

    // id = this.defaultId
    onAlert(): Observable<Alert> {
        return this.alertSubject.asObservable(); //.pipe(filter((x) => x && x.id === id))
    }

    // Add a new alert with a passed alert object
    addAlert(alert: Alert) {
        this.alert(alert);
    }

    // Convenience methods for each type of alert. Type is auto set and message is passed in with options like heading and keepAfterRouteChange
    success(message: string, options?: IAlertOptions) {
        this.alert(new Alert({ ...options, type: AlertType.success, message }));
    }

    error(message: String, options?: any) {
        this.alert(new Alert({ ...options, type: AlertType.danger, message }));
    }

    info(message: String, options?: any) {
        this.alert(new Alert({ ...options, type: AlertType.info, message }));
    }

    warn(message: String, options?: any) {
        this.alert(new Alert({ ...options, type: AlertType.warning, message }));
    }

    // Clear alerts
    clearAlerts(id = AlertService.defaultAlertID) {
        this.alertSubject.next({id});
    }

    // Capture alert ID and Routing option and add to BehaviorSubject
    // The corresponding alert component will subscribe to the BehaviorSubject and display the alert
    private alert(alert: Alert) {
        const defaultErrorKey: string = 'errors.error';
        const defaultMessageKey: string = 'errors.message';
        this.keepAfterRouteChange = alert.keepAfterRouteChange;

        // TODO: Need to determine the key to pass in to get the correct message header
        if (alert.heading === undefined || alert.heading === '') {
            alert.heading = defaultErrorKey;
        }

        if (alert.message === undefined || alert.message === '') {
            alert.message = defaultMessageKey;
        }

        // Translate the alert message and heading
        this.translate.get(alert.heading).subscribe((res: string) => {
            alert.heading = res;
        });

        this.translate.get(alert.message).subscribe((res: string) => {
            alert.message = res;
        });

        this.alertSubject.next(alert);
    }

    ngOnDestroy() {
        this.alertSubject.unsubscribe();
    }
}
