import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoggingService } from '@app-core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AlertService } from './alert.service';

@Injectable({
    providedIn: 'root',
})
export class ErrorInterceptorService implements HttpInterceptor {
    private errorMessage: String = '';
    private errorOptions = {
        heading: 'An error has occurred',
    };

    constructor(private alertService: AlertService) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        // this.spinner.show();
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.error instanceof ErrorEvent) {
                    LoggingService.log('This is a client side error');
                    this.errorMessage = `Error: ${error.error.message}`;
                } else if (error.error instanceof ProgressEvent) {
                    LoggingService.log('This is an underlying process error');
                    this.errorMessage = error.message;
                } else {
                    LoggingService.log('This is a server side error');
                    this.errorMessage = `Error Code: ${error.status},  Message: ${error.message}`;
                }
                LoggingService.log(this.errorMessage);
                // this.alertService.error(this.errorMessage, this.errorOptions);
                this.alertService.error('rest-errors.' + error, this.errorOptions);
                return throwError(() => new Error(this.errorMessage.toString()));
            }),
        );
    }
}
