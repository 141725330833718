import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { GlobalConstants, LoggingService } from '@app-core';
import { environment } from '@app-env';

@Injectable({
    providedIn: 'root',
})
export class EnvironmentService {
    private runningEnvironmentVal: string;
    private referrerVal: string = '';
    private originVal: string = '';
    private hostVal: string = '';
    private domainVal: string = '';
    private logoPathVal: string = '';
    private logoAltVal: string = '';
    private brandVal: string = '';
    private originingSiteVal: string = '';
    private favIcon!: HTMLLinkElement;
    private targetResourceUrlVal: string = '';
    private logonAttemptedVal: boolean | undefined = undefined;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private loggingService: LoggingService,
    ) {
        this.favIcon = document.querySelector('#appIcon') as HTMLLinkElement;
        this.runningEnvironmentVal = this.getEnvironment();
        this.referrerVal = this.document.referrer;
        this.originVal = this.document.location.origin;
        this.hostVal = this.document.location.host;  
        this.extractDomainAndBrand(this.hostVal);
        this.setBrandLogo();
        this.setFavIcon();
        this.setOriginatingSite();
        this.setTargetResourceUrl();
        this.logEnvironmentVars();      
    }

    // TO-DO - determine superfluous values and pare down
    private logEnvironmentVars() {
        LoggingService.log('Runtime: ' + this.runningEnvironmentVal);
        LoggingService.log('Referrer: ' + this.referrerVal);
        LoggingService.log('Origin: ' + this.originVal);
        LoggingService.log('Host: ' + this.hostVal);
        LoggingService.log('Domain: ' + this.domainVal);
        LoggingService.log('Image Location: ' + GlobalConstants.imageLocation);
        LoggingService.log('Brand: ' + this.brandVal);
        LoggingService.log('Environment: ' + environment.runtime);
        LoggingService.log('Production: ' + environment.production);
        LoggingService.log('OriginatingSite: ' + this.originingSiteVal);
        LoggingService.log('ProducerManagementRESTURL: ' + environment.producerManagementRESTURL);
        LoggingService.log('targetResourceUrl: ' + this.targetResourceUrlVal);
        LoggingService.log('LogonAttempted: ' + this.logonAttemptedVal);
    }

    get runningEnvironment(): string {
        return this.runningEnvironmentVal;
    }
    get recaptchaSiteKey(): string {
        return environment.recaptchaSiteKey;
    }
    get referrer(): string {
        return this.referrerVal;
    }
    get origin(): string {
        return this.originVal;
    }
    get host(): string {
        return this.hostVal;
    }
    get domain(): string {
        return this.domainVal;
    }
    get logo(): string {
        return this.logoPathVal;
    }
    get logoAlt(): string {
        return this.logoAltVal;
    }
    get brand(): string {
        return this.brandVal;
    }
    get logonAttempted(): boolean | undefined {
        return this.logonAttemptedVal;
    }
    get originatingSite(): string {
        return this.originingSiteVal;
    }
    get targetResourceUrl(): string {
        return this.targetResourceUrlVal;
    }

    set logonAttempted(value: boolean) {
        this.logonAttemptedVal = value;
    }

    private getEnvironment(): string {
        // do anything environment specific here
        if (window.location.hostname.indexOf('local') !== -1) {
            return 'local';
        } else if (window.location.hostname.slice(0, 3) === 'dev') {
            return 'dev';
        } else if (window.location.hostname.slice(0, 2) === 'qa') {
            return 'qa';
        } else {
            return 'prod';
        }
    }

    private setBrandLogo() {
        if (this.brand === GlobalConstants.brands.NMU) {
            this.logoPathVal = GlobalConstants.imageLocation + 'nmu-producer-logo.svg';
            this.logoAltVal = 'National Marine Underwriters brand logo';
        } else {
            this.logoPathVal = GlobalConstants.imageLocation + 'intact-producer-logo.svg';
            this.logoAltVal = 'Intact Insurance Specialty Solutions brand logo';
        }
    }

    private setFavIcon() {
        this.favIcon.href = GlobalConstants.imageLocation + 'intact-favicon.ico';
    }

    private extractDomainAndBrand(hostName: string): void {
        if (hostName != null) {
            const parts = hostName.split('.').reverse();
            if (parts != null && parts.length == 3) {
                this.domainVal = parts[1] + '.' + parts[0];
                this.brandVal = parts[1];
            } else if (parts != null && parts.length > 1) {
                this.domainVal = parts[1];
                this.brandVal = parts[1];
            } else {
                this.domainVal = parts[0];
                this.brandVal = parts[0];
            }
        }
    }

    private setTargetResourceUrl(): void {
        if (this.domain === 'bondclick.ca' || this.domain === 'onebeaconbondclick.com' || this.host === 'suretybonds.intactspecialty.com') {
            this.targetResourceUrlVal = environment.bondClickPath;
        } else {
            this.targetResourceUrlVal = environment.producerPortalPath;
        }
    }

    private setOriginatingSite(): void {
        const protocol = 'https://';

        if (this.runningEnvironmentVal !== 'prod') {
            this.originingSiteVal = protocol + this.runningEnvironmentVal + '.' + this.domain;
        } else {
            this.originingSiteVal = 'www.' + this.domain;
        }
    }
}
