<div class="app-logout">
    <i class="bi bi-check-circle logout-icon"></i>
    <div class="text-center title form-title">{{ 'headings.logoff' | translate }}</div>
    <p class="text-center title title-medium">{{ 'intro.logoff' | translate }}</p>

    <div class="button-row no-separator">
        <button class="btn btn-primary btn-lg" (click)="returnToLogin($event)">{{'buttons.log-back-in' | translate}}</button>
    </div>
    <div class="button-row no-separator">
        <p>{{ 'common.learn-more-about-us' | translate }} <a href={{originatingSite}} target='_self'>{{domain}}</a></p>
    </div>
</div>